/*------ bg backgrounds -------*/
%before-background{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	inset-inline-start: 0;
	inset-inline-end: 0;
	display: block;
	top: 0;
}
.bg-background:before {
	@extend %before-background;
}
.bg-background2:before {
	@extend %before-background;
	z-index: 0;
}
.bg-background-6:before {
	@extend %before-background;
	z-index: 1;
}
.bg-background-5:before {
	@extend %before-background;
	z-index: 0;
}
.bg-background-color:before, .bg-background3:before {
	@extend %before-background;
	z-index: 1;
}
.bg-background9:before {
	@extend %before-background;
	z-index: 1;
	background: rgba(236, 41, 107, 0.7);
}
.bg-background3 .header-text {
	position: relative;
	z-index: 10;
}
.bg-background9 .header-text {
	position: relative;
	z-index: 10;
	top: 20px;
}
.bg-background-color .content-text, .bg-background .header-text1 {
	position: relative;
	z-index: 10;
}
.bg-background2 .header-text {
	position: relative;
	z-index: 10;
	top: 90px;
	bottom: 70px;
}

.bg-background-color .counter-status {
	background: rgba($text-color, 0.22);
	padding: 20px;
	border-radius: 5px;
	box-shadow: 5px 4px 12px 4px rgba(49, 40, 154, 0.25);
}

.bg-background-pattern {
	&:before {
		@extend %before-background;
		z-index: 1;
		background:rgba(238, 235, 250, 0.6);
		opacity: .8;
	}
	.content-text {
		position: relative;
		z-index: 1;
	}
}

.bg-background .social-icons li {
	border: 0;
	background: rgba($text-color, 0.35);
}
.section-first.bg-background2:before {
	background: transparent !important;
}
/*------ bg backgrounds -------*/